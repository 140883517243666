import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import FormComponent from './components/form/form'

function App() {
  document.title = "Follow Up OPD";
  // let token = localStorage.getItem("isLoggedin")
  return (
    <div className="App">
    <Router>
      <div className="appMain">
      <Switch>
        <Route exact path="/" component={FormComponent} />
        {/* <Route component={NotFound} /> */}
      </Switch>
      </div>
    </Router>
  </div>  
  );
}

export default App;
