import { javaUrl, nodeUrl } from './environment/environment';

const getotp = `${javaUrl}otp/getotp`;
const verifyotp = `${javaUrl}otp/verifyotp`;
const token = `${javaUrl}oauth/token`;

const forwarding = `${nodeUrl}bd-dashboard/forwarding`;
const bdCommonDataList =  `${nodeUrl}bd-dashboard/bdCommonDataList`;
const createApp =  `${nodeUrl}bd-dashboard/createAppointment`;

export { getotp, verifyotp, token, forwarding, bdCommonDataList, createApp };