//******************************************
//    Change this for ENVIRONMENT !!!!     *
var production:any = true;   //  *
//******************************************
// ENVIRONMENT |  VALUE
//------------------------
//  Production |  true
//------------------------
//  Staging    |  false
//------------------------
//  Localhost  |   0
//------------------------
//DO NOT MESS WITH THE ENVIRONMENT VARIABLES
const javaUrl = production ? 'https://pristyntech.com/java/': 'http://13.233.108.135/';
const nodeUrl = production ? 'https://pristyntech.com/doc/':'http://13.233.238.40/bd-dash/'
export { production, javaUrl, nodeUrl };