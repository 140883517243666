import Swal from 'sweetalert2';
import { getotp, verifyotp, token, bdCommonDataList, forwarding, createApp } from './constant';
import { production } from './environment/environment';
import $ from 'jquery';

// error success
export function sweetAlert(title='',text='',icon='') {
    Swal.fire({
        title: title,
        text: text,
        timer: 2000,
        icon: icon,
        toast: true,
        showConfirmButton: false
      })
}

export function loginService(mobileNo) {
    try {
        return $.ajax({
          url: `${getotp}`,
          headers: {
            'Content-Type': 'application/json'
          },
          'processData': false,
          type: 'POST',
          data: JSON.stringify({
            "mobile": mobileNo
          })
        })
      } catch (err) {
        alert('error in code');
      }
}

export function otpService(mobileNo,otp) {
    try {
        return $.ajax({
          url: `${verifyotp}`,
          headers: {
            'Content-Type': 'application/json'
          },
          'processData': false,
          type: 'POST',
          data: JSON.stringify({
            "Mobile": mobileNo,
            "OTP":otp
          })
        })
      } catch (err) {
        alert('error in code');
      }
}

export function getToken() {
    try {
      var requestOptions
      var urlencoded
      var myHeaders
  
      if(production){
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Basic YW5kcm9pZC1jbGllbnQ6YW5kcm9pZC1zZWNyZXQ=");
        urlencoded = new URLSearchParams();
        urlencoded.append("username", "reviewPage_"+localStorage.getItem("mobile"));
        urlencoded.append("password", "mocked_appointmentId_"+localStorage.getItem("appointment_id"));
        urlencoded.append("grant_type", "otp");
      }else{
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Basic YW5kcm9pZC1jbGllbnQ6YW5kcm9pZC1zZWNyZXQ=");
        urlencoded = new URLSearchParams();
        urlencoded.append("username", "cityOpsStaging@pristyncare.com");
        urlencoded.append("password", "1234");
        urlencoded.append("grant_type", "password");
      }
  
      requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };
  
  
      return fetch(token, requestOptions)
        } catch (err) {
  
        }
  }

  export function getCommonData() {
    var settings = {
      "url": bdCommonDataList,
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        "Content-Type": "application/json"
      },
      "data": JSON.stringify({}),
    };

    try {
      return $.ajax(settings)
    } catch(err)  {
      alert('error in code');
    }
  }

  export function getAppDetails(appId) {
    var settings = {
      "url": forwarding,
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/json",
        "Authorization": `bearer ${localStorage.getItem("access_token")}`
      },
      "data": JSON.stringify(
        {
          "type": "parse",
          "endPoint": "followUpOPDDetails",
          "data": {
              "appId": appId
          }
      }
      ),
    };

    try {
      return $.ajax(settings)
    } catch(err)  {
      alert('error in code');
    }
  }

export function getSlots(data) {
  var settings = {
    "url": forwarding,
    "method": "POST",
    "timeout": 0,
    "headers": {
      "Content-Type": "application/json",
      "Authorization": `bearer ${localStorage.getItem("access_token")}`
    },
    "data": JSON.stringify(
      {
        "type": "parse",
        "endPoint": "checkFollowupSlots",
        "data":data
    }
    ),
  };

  try {
    return $.ajax(settings)
  } catch(err)  {
    alert('error in code');
  }
}


export function createAppointment(
                                  leadId,
                                  AppointmentDate,
                                  AppointmentStartTime,
                                  AppointmentEndTime,
                                  patientInsStatus,
                                  Hospital_clinic,
                                  SurgeryType,
                                  Disease,
                                  DocAssigned,
                                  opdType,
                                  BDAssigned
                                  ){

  var settings = {
    "url": createApp,
    "method": "POST",
    "timeout": 0,
    "headers": {
      "Authorization": `bearer ${localStorage.getItem("access_token")}`,
      "Content-Type": "application/json"
    },
    "data": JSON.stringify({
      "leadId": leadId,
      "ActiveOwner": "Doc",
      "appointmentStatus": "Active",
      "AppointmentDate": AppointmentDate,
      "AppointmentStartTime": {
        "__type": "Date",
        "iso": AppointmentStartTime
      },
      "AppointmentEndTime": {
        "__type": "Date",
        "iso":AppointmentEndTime
      },
      "Hospital_clinic": Hospital_clinic,
      "SurgeryType": SurgeryType,
      "BDStatus": "OPDScheduled",
      "Disease": Disease,
      "DocAssigned": DocAssigned,
      "CashAmount": 0,
      "PaymentMode": "Cash",
      "AppointmentType": "OPD",
      "opdType": opdType,
      "patientInsStatus": patientInsStatus,
      "advancePayment": [
        {
          "amount": 0,
          "receiptUrl": null,
          "date": new Date(),
          "userId": "",
          "source": "Self Booking - Patient"
        }
      ],
      "CreatedBy": "",
      "createFrom": "",
      "BDAssigned": BDAssigned,
      "AppointmentSource": "Self Booking - Patient",
      "emiPaymentLoanId": [],
      "checkFollowupFlag":true
    }),
  };

  try {
    return $.ajax(settings)
  } catch(err)  {
    alert('error in code');
  }
}