import React, { Component } from 'react'
import './form.css'
import logo from '../../asset/logo.png';
import { getToken, sweetAlert, getAppDetails, getSlots, createAppointment } from '../../service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserDoctor, faHome, faCheckCircle, faLinkSlash } from '@fortawesome/free-solid-svg-icons';

export class FormComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page:0,
            clinic:'',
            clinicId:'',
            docName:'',
            docId:'',
            disease:'',
            category:'',
            opdType:'',
            appId:'',
            minDate:'',
            maxDate:'',
            slots:[],
            slot:'',
            BDAssigned:'',
            leadId:'',
            emiPaymentLoanId:[],
            advancePayment:[],
            patientInsStatus:'',
            Hospital_clinic:'',
            date:'',
            loading:false,
            thankyou:false,
            newAppId:'',
            expDate:'',
            formExpired:false
        };
    }

    componentDidMount(){
        // 0125qkLFd3
        let appId

        if (window.location.href) {
            var url = new URL(window.location.href);
            var id = url.searchParams.get("appointment_id");
            var mobile
            var date
            if(id){
                localStorage.setItem('appointment_id', id.trim());
                appId = id.trim()
                mobile = url.searchParams.get("mobile");
                localStorage.setItem('mobile', mobile);
                date = url.searchParams.get("date");
                localStorage.setItem('date', date);
                let nowDate = new Date()
                let sentDate = new Date(date)
                let hours = nowDate - sentDate
                hours = hours / (1000*60*60)
                let formExpired = false
                if(date && hours>24){
                    formExpired = true
                }
                this.setState({appId:id.trim(),expDate:date,formExpired:formExpired})
            }
          }

        let res = getToken();
        res.then(
            response=>response.json()
        ).then(result => {
            if(result && result['access_token']){
            localStorage.setItem("access_token",result['access_token'])
            }
            let res1 = getAppDetails(appId)
            res1.then(response=>{
            if(response['data']['result']['data'][0]){
                this.setState({clinic:response['data']['result']['data'][0]['clinic']['Hospital Name']})
                this.setState({clinicId:response['data']['result']['data'][0]['clinic']['id']})
                this.setState({docName:response['data']['result']['data'][0]['docName']})
                this.setState({docId:response['data']['result']['data'][0]['docId']})
                this.setState({disease:response['data']['result']['data'][0]['disease']})
                this.setState({category:response['data']['result']['data'][0]['category']})
                this.setState({opdType:response['data']['result']['data'][0]['opdType']})
                this.setState({BDAssigned:response['data']['result']['data'][0]['BDAssigned']})
                this.setState({leadId:response['data']['result']['data'][0]['leadId']})
                this.setState({emiPaymentLoanId:response['data']['result']['data'][0]['emiPaymentLoanId']})
                this.setState({advancePayment:response['data']['result']['data'][0]['advancePayment']})
                this.setState({patientInsStatus:response['data']['result']['data'][0]['patientInsStatus']})
                this.setState({Hospital_clinic:response['data']['result']['data'][0]['clinic']})
            }
        })
        })
    }

    fetchSlots=(val)=>{
        let res2 = getSlots(
            {
                "docId":this.state.docId,
                "category":this.state.category,
                "hospitalId":this.state.clinicId,
                "disease":this.state.disease,
                "opdType":val
              }
        )
        res2.then(response=>{
            let len = 0
            if(response['data']['result']['data']){
                this.setState({slot:response['data']['result']['data']})
                len = response['data']['result']['data'].length
            }
            if(response['data']['result']['data'] && len){
                this.setState({minDate:response['data']['result']['data'][0][0]['date']})
                let otherLen = response['data']['result']['data'][len-1].length
                this.setState({maxDate:response['data']['result']['data'][len-1][otherLen-1]['date']})
            }
        })
    }

    selectDate=(date)=>{
        this.setState({date:date})
        let slotsDropdown  = []
        if(this.state.slot && this.state.slot.length)
        this.state.slot.forEach(el => {
            el.forEach(ml => {
                if(ml.date === date){
                    slotsDropdown.push(ml.start.hr+":"+ml.start.mm+" - "+ml.end.hr+":"+ml.end.mm)
                }
            });
        });
        if(slotsDropdown && slotsDropdown.length){
            this.setState({slots:slotsDropdown})
        }
    }

    finalSave=()=>{
        this.setState({loading:true})
        let myArray = this.state.slot.split(" ");
        let todayDate = new Date(this.state.date)
        let AppointmentStartTime = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate(),myArray[0].split(":")[0] , myArray[0].split(":")[1]);
        let AppointmentEndTime = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate(), myArray[2].split(":")[0], myArray[2].split(":")[1]);
        let hosp = this.state.Hospital_clinic;
        if(this.state.opdType==='Consultation') {
            hosp = {
                "Hospital City": "Bangalore",
                "Hospital Name": "Online Consulting",
                "id": "59GKeE58Ik"
            }
        }
        let res3 = createAppointment(
            this.state.leadId,
            this.state.date,
            AppointmentStartTime,
            AppointmentEndTime,
            this.state.patientInsStatus,
            hosp,
            this.state.disease,
            this.state.category,
            this.state.docId,
            this.state.opdType,
            this.state.BDAssigned
        )
        res3.then(response=>{
            this.setState({loading:false})
            let code = "success"
            if(response['appData']['data']['result']['message']){
                if(response['appData']['data']['result']['message']['status']===-1){
                    code = "error"
                    this.setState({thankyou:false})
                }
                sweetAlert('',response['appData']['data']['result']['message'],code);
                if(response['appData']['data']['result']['AppointmentId']){
                    this.setState({newAppId:response['appData']['data']['result']['AppointmentId']})
                    this.setState({thankyou:true})
                }
            }
        })
    }

    first=(val)=>{
        this.setState({opdType:val})
        this.setState({page:1})
        this.fetchSlots(val)
    }

    render() {
        return (
            <div className="mycontainer">
                <h2><img src={logo} alt="Logo" />
                    <span><i className="fa fa-linkedin-square" aria-hidden="true"></i></span>
                </h2>
            { this.state.appId && !this.state.formExpired && this.state.page === 0 && 
            <div className="intro-text">
                <p>Choose your mode of Consultation</p>
            </div>}
            {!this.state.thankyou && this.state.appId && this.state.page === 1 && 
            <div className="intro-text">
                <p>Free Consultation Booking Form</p>
            </div>}
            {this.state.thankyou &&
                <div>
                    <FontAwesomeIcon icon={faCheckCircle} style={{color: '#0F9D58',fontSize:'50px'}} /><br/><br/>
                    <span className="thankyou">
                        Congratulation! Your Appointment with ID-{this.state.newAppId} is confirmed.<br/> 
                    </span><br/>
                    <span className="thankyouHelp">
                        In case of any queries pls feel free to reach us at <a className="telNum" href="tel:7303763211">7303763211</a>.
                    </span>
                </div>
            }
            {!this.state.appId &&
                <div className="warn">
                    Appoint Id Missing !
                </div>
            }
            {this.state.appId && this.state.formExpired &&
                <div className="warn">
                  <FontAwesomeIcon icon={faLinkSlash} style={{color: 'grey',fontSize:'20px'}} /> Link Expired!
                </div>
            }
            {this.state.appId && !this.state.formExpired && this.state.page === 0 && 
            <>
            <div className="row">
                <div className="col-6">
                    <a onClick={(e)=>{this.first("Consultation")}} type="button" className="btn btn-primary">
                        Online
                        <br/>
                        <FontAwesomeIcon icon={faHome} style={{color: 'white',fontSize:'50px'}} />
                    </a>
                </div>
                <div className="col-6">
                    <a onClick={(e)=>{this.first("Followup-IPD")}} type="button" className="btn btn-primary">
                        Physical
                        <br/>
                        <FontAwesomeIcon icon={faUserDoctor} style={{color: 'white',fontSize:'50px'}} />
                    </a>
                </div>
            </div>
            </>
            }
            {!this.state.thankyou && this.state.page === 1 && 
            <>
            <div className="inputs">
                {this.state.opdType==='Followup-IPD' &&
                    <div className="input">
                        <input type="text" name="doctor" id="doctor" value={this.state.clinic} disabled />
                        <label htmlFor="doctor">
                            Clinic
                        </label>
                    </div>
                }
                <div className="input">
                    <input className="fixedLabel" type="text" name="doctor" id="doctor" value={this.state.docName} disabled />
                    <label htmlFor="doctor">
                        Doctor
                    </label>
                </div>
                <div className="input">
                    <input className="fixedLabel" type="text" name="doctor" id="doctor" value={this.state.disease} disabled />
                    <label htmlFor="doctor">
                        Disease
                    </label>
                </div>
                <div className="input">
                    <input className="dateSelect" type="date" onInput={(e)=>this.selectDate(e.target.value)} min={this.state.minDate} max={this.state.maxDate} name="dop" id="dop" />
                    <label htmlFor="dop">
                        Date of Appointment
                    </label>
                </div>
                <div className="input">
                    <select onChange={(e)=>{this.setState({slot:e.target.value})}} className="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                        <option selected disabled>Slots</option>
                        {(!this.state.slots || this.state.slots.length===0) && this.state.date &&  <option selected disabled>No Slots Available</option> }
                        {
                            this.state.slots && this.state.slots.length>0 &&  this.state.slots.map((item,index)=>{
                                return(
                                    <option value={item}>{item}</option> 
                                 )
                            })
                        }

                    </select>
                </div>
            </div>
            <button disabled={this.state.loading} onClick={(e)=>{this.finalSave()}}  className='btn btn-primary btn-block mt-4 mb-4 customBtn'>Submit</button>
            </>
            }
        </div>
        )
    }
 }

 export default FormComponent;